import React, { useState } from 'react';
import { observer } from 'mobx-react';
import rictangle from '../../images/rictangle.png';

const PackagesSection = () => {
  const [activeTab, setActiveTab] = useState('monthly');

  const monthlyPackages = [
    { name: 'Basic', price: '$50/month', features: ['25 conversions/month', '10 pages per conversion', 'Basic PDF customization', 'Email support'] },
    { name: 'Professional', price: '$100/month', features: ['50 conversions/month', '50 pages per conversion', 'Standard PDF customization', 'Priority email support'] },
    { name: 'Corporate', price: '$200/month', features: ['200 conversions/month', '100 pages per conversion', 'Advanced PDF customization', '24/7 chat & email support'] },
  ];

  const yearlyPackages = [
    { name: 'Basic', price: '$500/year', features: ['25 conversions/month', '10 pages per conversion', 'Basic PDF customization', 'Email support'] },
    { name: 'Professional', price: '$1000/year', features: ['50 conversions/month', '50 pages per conversion', 'Standard PDF customization', 'Priority email support'] },
    { name: 'Corporate', price: '$2000/year', features: ['200 conversions/month', '100 pages per conversion', 'Advanced PDF customization', '24/7 chat & email support'] },
  ];

  return (
    <div className="container">
      <div className="packages-section">
        <h3>Our Packages</h3>
        <div className="tabs">
          <button
            className={`tab ${activeTab === 'monthly' ? 'active' : ''}`}
            onClick={() => setActiveTab('monthly')}
          >
            Monthly
          </button>
          <button
            className={`tab ${activeTab === 'yearly' ? 'active' : ''}`}
            onClick={() => setActiveTab('yearly')}
          >
            Yearly
          </button>
        </div>
    
        <div className="packages">
          <div className="row">
            {(activeTab === 'monthly' ? monthlyPackages : yearlyPackages).map((pkg, index) => (
              <div key={index} className="col-md-4 col-sm-12 col-12">
                <div className="package">
                  <img src={rictangle} alt={pkg.name} />
                  <h3>{pkg.name}</h3>
                  <p className="price">{pkg.price}</p>
                  <span>Features</span>
                  <ul>
                    {pkg.features.map((feature, featureIndex) => (
                      <li key={featureIndex}>{feature}</li>
                    ))}
                  </ul>
                  <button
                    className="subscribe-btn"
                    onClick={() =>
                      window.location.href = `/auth/sign-up?package_id=${pkg.name}`
                    }
                  >
                    Choose Plan
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(PackagesSection);
