//@ts-nocheck
import React, { useEffect } from 'react';
import newImage from './images/reviews.gif'; // Ensure correct image path=
import 'aos/dist/aos.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CosmicOrbitLoader from '../components/CosmicOrbitLoader';
import AOS from 'aos';
import $ from 'jquery';
import 'slick-carousel';
import { dynamicLoader } from '../helpers/helpers';
import './styles.css'
import Navbar from './components/Navbar';
import WebToPdfSection from './components/sections/WebToPdfSection';
import PackagesSection from './components/sections/PackagesSection';
import { useLocation } from 'react-router-dom'; // Import useLocation

const WebToPDF = () => {
    const [showPreloader, setShowPreloader] = React.useState(true);

  useEffect(() => {
    dynamicLoader.addScripts();
    setTimeout(() => {
      setShowPreloader(false);
    },500);
  });

  const location = useLocation(); // Get the current route

  useEffect(
    () => {
    
        if (location.pathname === '/') {
            document.body.classList.add('home-page');
        }else{
            document.body.classList.remove('home-page');
        }

      		$(document).ready(function () {
			setTimeout(() => {
				$(".testimonial-slider").slick({
					dots: true,
					infinite: true,
					speed: 300,
					arrows: false,
					slidesToShow: 3,
					slidesToScroll: 1,
					initialSlide: 0,
					responsive: [
						{
							breakpoint: 1024,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 1,
								infinite: true,
								dots: true,
							},
						},
						{
							breakpoint: 600,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
							},
						},
					],
				});
			}, 1000);
			// Remove the class from all slides

			// Add the class to the last active slide
		});
    }, [location.pathname]
  )
  if (showPreloader) {
    return <CosmicOrbitLoader />;
  }
  
  return (
    <>
    <Navbar/>
    {/* Header END */}

    {/* BODY START */}

    {/* Hero Section start */}
    {/* <div className="hero-section-wrap"  >
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                    <h1 data-aos="fade-down">Convert web to PDF</h1>
                    <img src="images/hero.svg" data-aos="zoom-in"/>
                    <p  >Convert webpage to PDF with one click!</p>
                    <p   data-aos-delay="100">Copy and paste the webpage URL and click the <a
                            href="#">GET PDF</a> button.</p>
                    <form className="search-bar"   data-aos-delay="200">
                        <div className="input-container"  >
                            <i className="fas fa-link"></i>
                            <input type="text" placeholder="www.example.com" />
                        </div>
                        <button type="button" className="options-btn"   >OPTIONS</button>
                        <button type="submit" className="pdf-btn"    data-aos-delay="100">GET PDF</button>
                    </form>
                </div>
            </div>
        </div>
    </div> */}
<WebToPdfSection/>
<div className="container useage">
    <div className="row">
        <div className="col-md-12">
        <span data-aos="fade-in" data-aos-delay="300">
          *Size and daily usage limitations may apply.
        </span>
        </div>
    </div>
</div>
    <div className="pdfonline-wrap"  >
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                
                    <h2 data-aos="fade-down">How to convert a webpage to PDF online</h2>
                    <div className="grid-container">
                        {/* Grid Item 1 */}
                        <div className="grid-item"  >
                            <h2>1</h2>
                            <p>In a web browser, open the desired web page and copy the URL. Paste the URL that you want
                                to convert into the text box.</p>
                        </div>
                        <div className="image-section">
                            <img src="images/how-111.svg" alt="Image Description 1"/>
                        </div>

                        {/* Grid Item 2 */}
                        <div className="grid-item">
                            <h2>2</h2>
                            <p>Click the "Options" button to change the default conversion settings. Then click the "Get
                                PDF" button.</p>
                        </div>
                        <div className="image-section">
                            <img src="images/how-222.svg" alt="Image Description 2"/>
                        </div>

                        <div className="grid-item mobile-div">
                            <h2>3</h2>
                            <p>Please wait once the<br/>file conversion starts.<br/>The conversion will be<br/>completed
                                within a<br/>few seconds.</p>
                        </div>
                        <div className="image-section mobile-div">
                            <img src="images/how-333.svg" alt="Image Description 3"/>
                        </div>
                        {/* aa */}
                        {/* Grid Item 3 (Desktop) */}
                        <div className="image-section desktop-div">
                            <img src="images/how-333.svg" alt="Image Description 3"/>
                        </div>
                        <div className="grid-item desktop-div">
                            <h2>3</h2>
                            <p>Please wait once the<br/>file conversion starts.<br/>The conversion will be<br/>completed
                                within a<br/>few seconds.</p>
                        </div>

                        {/* Grid Item 4 */}
                        <div className="grid-item mobile-div">
                            <h2>4</h2>
                            <p>Click the download<br/>button to save your<br/>new PDF.</p>
                        </div>
                        <div className="image-section mobile-div">
                            <img src="images/how-444.svg" alt="Image Description 4"/>
                        </div>
                        <div className="image-section desktop-div">
                            <img src="images/how-444.svg" alt="Image Description 4"/>
                        </div>
                        <div className="grid-item desktop-div">
                            <h2>4</h2>
                            <p>Click the download<br/>button to save your<br/>new PDF.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <PackagesSection/>
    
    <div className="experience-wrap"  >
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                    <h3 data-aos="fade-down">Experience seamless <br/>conversion at your fingertips!</h3>
                    <h4   data-aos-delay="100">Effortlessly transform any URL into a PDF with a single
                        click.</h4>
                    <p   data-aos-delay="200">Welcome to Web2PDF, your all-in-one solution for
                        converting web content into a versatile, easily shareable PDF format. Our tool is designed to
                        meet the needs of professionals, students, and casual users alike, providing a seamless and
                        efficient way to capture and preserve online information.</p>
                </div>
            </div>
        </div>
    </div>
    <div className="chose-wrap"  >
        <div className="container">
            <div className="row justify-content-center align-items-center">
                <div className="col-md-6"  >
                    <h3>Why Choose Web2PDF?</h3>
                    <span   data-aos-delay="100">For Professionals</span>
                    <p   data-aos-delay="200">Are you a researcher, journalist, or business
                        professional needing to archive web pages for future reference? Web2PDF allows you to
                        effortlessly convert and store web content in a reliable PDF format, ensuring your important
                        information is always accessible, even offline.</p>

                    <span   data-aos-delay="300">For Students</span>
                    <p   data-aos-delay="400">Compiling resources for a project or thesis? Web2PDF
                        simplifies the process by enabling you to quickly convert multiple URLs into organized PDFs.
                        This makes it easier to annotate, highlight, and share your research with classNamemates and
                        professors.</p>

                    <span   data-aos-delay="500">For Everyday Users</span>
                    <p   data-aos-delay="600">Whether you're saving recipes, preserving articles, or
                        keeping track of your favorite blogs, Web2PDF helps you maintain a personal archive of web
                        content that is easy to navigate and free from the clutter of advertisements and pop-ups.</p>
                </div>
                <div className="col-md-6" data-aos="zoom-in">
                    <img src="images/why-chose.png"/>
                </div>
            </div>
        </div>
    </div>

    <div className="feature-wrap"  >
        <div className="container">
            <div className="row">
                <h3 data-aos="fade-down">Key Features</h3>

                <div className="col-md-3"  >
                    <div className="feature-box">
                        <h5>One-Click <br/>Conversion</h5>
                        <p>Transform any web page<br/> into a PDF with just one<br/> click, making the process <br/>fast
                            and straightforward.</p>
                    </div>
                </div>

                <div className="col-md-3"   data-aos-delay="100">
                    <div className="feature-box">
                        <h5>Customizable <br/>Options</h5>
                        <p>Choose from various<br/> settings to tailor the PDF<br/> output to your needs,<br/>including
                            page size,<br/> orientation, and more.</p>
                    </div>
                </div>

                <div className="col-md-3"   data-aos-delay="200">
                    <div className="feature-box">
                        <h5>Secure and <br/>Private</h5>
                        <p>Your data security is our<br/> priority. Web2PDF <br/>ensures that all<br/> conversions are
                            handled <br/>with the utmost<br/> confidentiality and privacy.</p>
                    </div>
                </div>

                <div className="col-md-3"   data-aos-delay="300">
                    <div className="feature-box">
                        <h5>Multi-Platform <br/>Support</h5>
                        <p>Accessible from any device,<br/> Web2PDF allows you to <br/>convert web pages to PDFs<br/>
                            whether you’re using a<br/> desktop, tablet,<br/>or smartphone.</p>
                    </div>
                </div>

                <h4   data-aos-delay="400">Web2PDF is more than just a conversion tool; it's a gateway
                    to efficient and organized digital documentation.</h4>
                <h4   data-aos-delay="500">Experience the convenience and power of seamless web content
                    conversion at your fingertips.</h4>
            </div>
        </div>
    </div>

    <div className="virtual-wrap"  >
        <div className="container">
            <div className="row justify-content-center align-items-center">
                <div className="col-md-6"  >
                    <span data-aos="fade-down">Did you know?</span>
                    <h3   data-aos-delay="100">Webpage to PDF</h3>
                    <h4   data-aos-delay="200">The ultimate virtual print experience</h4>
                    <p   data-aos-delay="300">
                        Converting a webpage to PDF is akin to digitally printing your webpage, but with added benefits.
                        By transforming your HTML files into PDFs, you create an editable, customizable virtual copy
                        that also helps save paper! The quality of PDF conversions depends heavily on the webpage's
                        coding and its printer-friendliness. If a webpage appears strange when printed, it's likely to
                        appear similarly in PDF format. However, there are solutions available. Some websites provide an
                        embedded print button that renders the page's content in a preformatted version, ensuring a more
                        consistent PDF output.
                    </p>
                </div>
                <div className="col-md-6" data-aos="zoom-in">
                    <img src="images/did-you-know.svg" className="img-responsive"/>
                </div>
            </div>
        </div>
    </div>

    <div className="benefits-wrap"  >
        <div className="container">
            <div className="row">
                <div className="col-md-12" data-aos="fade-down">
                    <h3>Benefits of using Web2PDF</h3>
                    <p>Unlock the full potential of your web content with Web2PDF. Convert, customize, and securely save
                        <br/>
                        web pages as PDFs, ensuring you have access to important information whenever you need it.
                        Explore <br/>
                        the key advantages of using our tool to enhance your productivity and document management.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="benefit-box"  >
                        <div className="img-div" data-aos="zoom-in">
                            <img src="images/user.png"/>
                        </div>
                        <div className="benefit-details"  >
                            <span>User Friendly</span>
                            <p>Designed for simplicity, ensuring a seamless<br/>experience with no need for technical
                                expertise.</p>
                        </div>
                    </div>
                    <div className="benefit-box"   data-aos-delay="100">
                        <div className="img-div" data-aos="zoom-in" data-aos-delay="100">
                            <img src="images/user.png"/>
                        </div>
                        <div className="benefit-details"   data-aos-delay="100">
                            <span>Fast and Reliable</span>
                            <p>Count on our robust servers for prompt and <br/>trouble-free conversions, ensuring a
                                seamless <br/>experience.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="benefit-box"   data-aos-delay="200">
                        <div className="img-div" data-aos="zoom-in" data-aos-delay="200">
                            <img src="images/user.png"/>
                        </div>
                        <div className="benefit-details"   data-aos-delay="200">
                            <span>High Quality Outputs</span>
                            <p>Enjoy pristine PDFs in every conversion, ensuring <br/>an exceptional viewing experience.
                            </p>
                        </div>
                    </div>
                    <div className="benefit-box"   data-aos-delay="300">
                        <div className="img-div" data-aos="zoom-in" data-aos-delay="300">
                            <img src="images/user.png"/>
                        </div>
                        <div className="benefit-details"   data-aos-delay="300">
                            <span>Simplify file sharing with PDFs</span>
                            <p>A universal format accessible on computers and <br/>mobile devices, ensuring easy sharing
                                of web <br/>content with others.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="user-wrap"  >
        <div className="container">
            <div className="row">
                <div className="col-md-12" data-aos="fade-down">
                    <h3>Real User Experiences</h3>
                    <p>
                        Discover the impact Web2PDF is making through user reviews.<br/>
                        Learn how it enhances productivity, organization, and overall <br/>
                        efficiency in document management.
                    </p>
                    <img src="images/reviews.png" alt="Why Choose" className="img-responsive"/>
                    <div className="testimonial-slider"  >
                        <div className="testimonial-item"  >
                            <div className="testimonial-content">
                                <i className="fas fa-user"></i>
                                <p>Web2PDF has been a game-changer<br/> for my work. As a researcher, I often<br/> need to
                                    save web pages for offline<br/> reading and reference. This tool<br/> makes it so easy
                                    to convert web<br/> pages to PDF with just a few clicks.<br/> It has significantly
                                    improved my<br/> productivity and organization. I highly<br/> recommend it!</p>
                                <h3>Sarah Lawrence</h3>
                                <p>RESEARCHER</p>
                            </div>
                        </div>
                        <div className="testimonial-item"   data-aos-delay="100">
                            <div className="testimonial-content">
                                <i className="fas fa-user"></i>
                                <p>I use Web2PDF daily for my job, and<br/> it has made managing documents so<br/> much
                                    easier. The conversion process<br/> is quick and the quality of the PDFs<br/> is
                                    excellent. Plus, I love that I don't<br/> need to download any software. It's<br/> a
                                    must-have tool for anyone dealing<br/> with web content regularly.</p>
                                <h3>James Peterson</h3>
                                <p>MARKETING MANAGER</p>
                            </div>
                        </div>
                        <div className="testimonial-item"   data-aos-delay="200">
                            <div className="testimonial-content">
                                <i className="fas fa-user"></i>
                                <p>Web2PDF is a fantastic tool for<br/> anyone who needs to save web<br/> pages for later
                                    use. It is especially<br/> useful for creating printable versions<br/> of web content.
                                    The interface is<br/> user-friendly, and the service is<br/> reliable. This tool has
                                    definitely<br/> increased my efficiency in handling<br/> documents.</p>
                                <h3>Emily Roberts</h3>
                                <p>FREELANCE WRITER</p>
                            </div>
                        </div>
                        <div className="testimonial-item"   data-aos-delay="300">
                            <div className="testimonial-content">
                                <i className="fas fa-user"></i>
                                <p>Web2PDF is a fantastic tool for<br/> anyone who needs to save web<br/> pages for later
                                    use. It is especially<br/> useful for creating printable versions<br/> of web content.
                                    The interface is<br/> user-friendly, and the service is<br/> reliable. This tool has
                                    definitely<br/> increased my efficiency in handling<br/> documents.</p>
                                <h3>Emily Roberts</h3>
                                <p>FREELANCE WRITER</p>
                            </div>
                        </div>
                        <div className="testimonial-item"   data-aos-delay="400">
                            <div className="testimonial-content">
                                <i className="fas fa-user"></i>
                                <p>Web2PDF is a fantastic tool for<br/> anyone who needs to save web<br/> pages for later
                                    use. It is especially<br/> useful for creating printable versions<br/> of web content.
                                    The interface is<br/> user-friendly, and the service is<br/> reliable. This tool has
                                    definitely<br/> increased my efficiency in handling<br/> documents.</p>
                                <h3>Emily Roberts</h3>
                                <p>FREELANCE WRITER</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="broswer-wrap">
        <div className="container">
            <div className="row">
                <div className="col-md-12"   data-aos-duration="1000">
                    <h3>Supported Browsers</h3>
                    <p>Our online HTML to PDF converter seamlessly supports the latest versions of all major browsers,
                        ensuring compatibility<br/>
                        with Chrome, Firefox, Edge, Safari, Opera, Internet Explorer, Vivaldi, and Brave. This ensures a
                        versatile user<br/>
                experience across different platforms and devices.
                </p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="icon-container">
                        <img src="images/chrome.png" alt="Chrome" className="icon" />
                        <img src="images/firefox.png" alt="Firefox" className="icon" />
                        <img src="images/edge.png" alt="Edge" className="icon" />
                        <img src="images/safari.png" alt="Safari" className="icon" />
                        <img src="images/opera.png" alt="Opera" className="icon" />
                        <img src="images/brave.png" alt="Brave" className="icon" />
                        <img src="images/vivaldi.png" alt="Vivaldi" className="icon"/>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="plan-wrap"   data-aos-duration="1000">
        <div className="container">
            <div className="row">
                <div className="col-md-12 plan-details"   data-aos-duration="1500">
                    <h3>Find the perfect plan for your needs</h3>
                    <h4>Explore Our Flexible Pricing Options</h4>
                    <p>Discover the plan that best fits your requirements and start converting web pages to PDFs with
                        ease. Whether you're a<br/>
                        casual user or need extensive features, our pricing plans offer flexibility and value to suit
                        your needs. <br/>
                        Choose the right plan and enjoy seamless document management today!</p>
                </div>
            </div>
        </div>
    </div>
    <div className="faq-wrap">
        <div className="container">
            <div className="row">
                <div className="col-md-12 faq-details">
                    <h3>Unveiling the answers to <br/>
                        your common questions</h3>
                    <p>Web2pdf is your go-to tool for converting web pages into PDF documents quickly and easily.
                        Whether you're looking to save <br/>
                        web content for offline reading or create printable versions of web pages, web2pdf has got you
                        covered. Here are the answers <br/>
                        to some frequently asked questions to help you make the most of this powerful online tool.</p>
                </div>
            </div>
            <div className="row justify-content-center align-items-center">
                <div className="col-md-6">
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i className="fas fa-angles-right"></i>
                                    What is web2pdf?
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Web2pdf is an online tool that allows you to convert web pages into PDF documents
                                    quickly and easily. It is designed to help users save web content in a portable and
                                    printable format.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i className="fas fa-angles-right"></i> Is web2pdf free to use?
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Yes, web2pdf offers a free version that allows users to convert web pages to PDF
                                    without any cost. However, there may be additional features or higher usage limits
                                    available through paid plans.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i className="fas fa-angles-right"></i>Do I need to download any software to use
                                    web2pdf?
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    No, you do not need to download any software to use web2pdf. It is a web-based tool,
                                    so you can access and use it directly from your browser.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingfour">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#headingfour" aria-expanded="false" aria-controls="collapsefour">
                                    <i className="fas fa-angles-right"></i>How do I convert a web page to a PDF using
                                    web2pdf?
                                </button>
                            </h2>
                            <div id="headingfour" className="accordion-collapse collapse" aria-labelledby="headingfour"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    To convert a web page to a PDF using web2pdf, follow these steps:
                                    <ul>
                                        <li>Go to the web2pdf website.</li>
                                        <li>Enter the URL of the web page you want to convert in the provided input
                                            field.</li>
                                        <li>Click the "Convert" button.</li>
                                        <li>Wait for the conversion to complete.</li>
                                        <li>Download the resulting PDF file to your device.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingfive">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#headingfive" aria-expanded="false" aria-controls="collapsefive">
                                    <i className="fas fa-angles-right"></i>Can I convert multiple web pages at once?

                                </button>
                            </h2>
                            <div id="headingfive" className="accordion-collapse collapse" aria-labelledby="headingfive"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Web2pdf typically allows you to convert one web page at a time. If you need to
                                    convert multiple web pages, you will need to repeat the conversion process for each
                                    URL. Some advanced or paid versions may offer batch conversion features.

                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingsix">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#headingsix" aria-expanded="false" aria-controls="collapsesix">
                                    <i className="fas fa-angles-right"></i>Are there any limits on the number of PDF
                                    conversions?
                                </button>
                            </h2>
                            <div id="headingsix" className="accordion-collapse collapse" aria-labelledby="headingsix"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    The free version of web2pdf may have limits on the number of PDF conversions you can
                                    perform within a certain time frame. These limits can vary, so it's best to check
                                    the website for specific details. Paid plans often offer higher or unlimited
                                    conversion limits.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingseven">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#headingseven" aria-expanded="false" aria-controls="collapseseven">
                                    <i className="fas fa-angles-right"></i>What customization options are available for PDF
                                    conversion?
                                </button>
                            </h2>
                            <div id="headingseven" className="accordion-collapse collapse" aria-labelledby="headingseven"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Web2pdf offers various customization options for PDF conversion, including:
                                    <ul>
                                        <li>Selecting the page size and orientation (e.g., A4, Letter, portrait,
                                            landscape).
                                        </li>
                                        <li>Adjusting margins.</li>
                                        <li>Including or excluding images and links.</li>
                                        <li>Setting a header and footer.</li>
                                        <li>Configuring other advanced settings depending on the specific requirements.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingeight">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#headingeight" aria-expanded="false" aria-controls="collapseeight">
                                    <i className="fas fa-angles-right"></i>Is web2pdf mobile-friendly?
                                </button>
                            </h2>
                            <div id="headingeight" className="accordion-collapse collapse" aria-labelledby="headingeight"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Yes, web2pdf is mobile-friendly. You can access and use the tool on your mobile
                                    device’s browser, allowing you to convert web pages to PDFs on the go.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingnine">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#headingnine" aria-expanded="false" aria-controls="collapsnine">
                                    <i className="fas fa-angles-right"></i>Can I use web2pdf without registering?
                                </button>
                            </h2>
                            <div id="headingnine" className="accordion-collapse collapse" aria-labelledby="headingnine"
                                data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Yes, you can use web2pdf without registering. The tool is designed to be
                                    user-friendly and accessible without the need for creating an account or signing in.
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-md-6">
                    <img src="images/faq.png" className="img-responsive"/>
                </div>
            </div>
        </div>
    </div>


    <div className="reach-wrap">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h3>Reach Out</h3>
                    <p>We’re happy to help or answer any questions about Web2pdf. Please <br/>
                        provide your information and we will response as soon as possible.</p>
                </div>
            </div>
            <div className="row">
                <div className="contact-form">
                    <form action="#" method="post">
                        <div className="form-row">
                            <div className="form-group">
                                <label for="name">Name</label>
                                <input type="text" id="name" name="name" required/>
                            </div>
                            <div className="form-group">
                                <label for="email">Email</label>
                                <input type="email" id="email" name="email" required/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label for="subject">Subject</label>
                            <input type="text" id="subject" name="subject" required/>
                        </div>
                        <div className="form-group">
                            <label for="message">Message</label>
                            <textarea id="message" name="message" rows="5" required></textarea>
                        </div>
                        <button type="submit" className="btn-submit">
                            Send Message
                            <i className="fas fa-location-arrow"></i>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    {/* Footer */}
    <footer>
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                        <a href="#" className="f-logo">
                            <img src="images/logo.svg" className="img-responsive"/>
                        </a>
                        <p>Transforming Web Content into <br/>
                            Portable, Professional Documents</p>
                    </div>
                    <div className="col-md-5">
                        <div className="footer-links">
                            <ul>
                                <li><a href="#">About</a></li>
                                <li><a href="#">Benefits</a></li>
                                <li><a href="#">Reviews</a></li>
                            </ul>

                            <ul>
                                <li><a href="#">Pricing</a></li>
                                <li><a href="#">FAQ</a></li>
                                <li><a href="#">Contact</a></li>
                            </ul>

                            <ul>
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">Cookie Policy</a></li>
                                <li><a href="#">Terms of Use</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-6">
                        <p>Copyright © 2024 www.web2pdf.com All rights reserved.</p>
                    </div>
                    <div className="col-md-6">
                        <a href="#" className="up-arrow"><img src="images/arrow-up.png" className="img-responsive"/></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </>
  );
};

export default WebToPDF;
