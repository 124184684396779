//@ts-nocheck
import React, { useState, useEffect } from "react";
import "../styles/ConversionOptions.css";
import subscriptionState from "../../shared/mobx/subscription-state";
import { observer } from "mobx-react";
import auth from "../../shared/mobx/auth-state";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../components/bootstrap/Modal";
import ScreenResolutionDropdown from "./ScreenResolutionDropdown";
// const convertOptions = {
//     sizes: [
//         { name: "Letter (8.5in x 11in)", value: "816 x 1056" },    // 8.5in x 300 DPI = 2550; 11in x 300 DPI = 3300
//         { name: "Legal (8.5in x 14in)", value: "816 x 1344" },     // 8.5in x 300 DPI = 2550; 14in x 300 DPI = 4200
//         { name: "Tabloid (11in x 17in)", value: "1056 x 1632" },    // 11in x 300 DPI = 3300; 17in x 300 DPI = 5100
//         { name: "Ledger (17in x 11in)", value: "1632 x 1056" },     // 17in x 300 DPI = 5100; 11in x 300 DPI = 3300
//         { name: "A0 (33.1in x 46.8in)", value: "3177.6 x 4492.8" },    // 33.1in x 300 DPI = 9930; 46.8in x 300 DPI = 14040
//         { name: "A1 (23.4in x 33.1in)", value: "2246.4 x 3177.6" },     // 23.4in x 300 DPI = 7020; 33.1in x 300 DPI = 9930
//         { name: "A2 (16.5in x 23.4in)", value: "1584 x 2246.4" },     // 16.5in x 300 DPI = 4950; 23.4in x 300 DPI = 7020
//         { name: "A3 (11.7in x 16.5in)", value: "1123.2 x 1584" },     // 11.7in x 300 DPI = 3510; 16.5in x 300 DPI = 4950
//         { name: "A4 (8.3in x 11.7in)", value: "796.8 x 1123.2" },      // 8.3in x 300 DPI = 2480; 11.7in x 300 DPI = 3510
//         { name: "A5 (5.8in x 8.3in)", value: "556.8 x 796.8" },       // 5.8in x 300 DPI = 1740; 8.3in x 300 DPI = 2480
//         { name: "A6 (4.1in x 5.8in)", value: "393.6 x 556.8" }        // 4.1in x 300 DPI = 1230; 5.8in x 300 DPI = 1740
//     ]
// };

const convertOptions = {
    sizes: [
        { name: "Desktop and Laptop (1920px x 1080px)", value: "1920x1080" },
        { name: "Desktop and Laptop (1366px x 768px)", value: "1366x768" },
        { name: "Desktop and Laptop (1440px x 900px)", value: "1440x900" },
        { name: "iPad Portrait (768px x 1024px)", value: "768x1024" },
        { name: "iPad Landscape (1024px x 768px)", value: "1024x768" },
        { name: "Samsung Galaxy Tab Portrait (800px x 1280px)", value: "800x1280" },
        { name: "iPhone 6/7/8 Portrait (375px x 667px)", value: "375x667" },
        { name: "iPhone X/11/12/13/14 Pro Max Portrait (414px x 896px)", value: "414x896" },
        { name: "Samsung Galaxy S9 Portrait (360px x 640px)", value: "360x640" }
    ]
};


const onlyForPaidUser = [
    "watermark",
    "displayHeaderFooter",
    "headerTemplate",
    "footerTemplate",
    "watermarkOptions",
];

const onlyForFree = [
    'format'
]


const ConversionOptions = ({ formState, setFormState, initialFormState, toggleOptions,openbulkDataModal, handleGetPages }) => {

    useEffect(() => {
        const fetchData = async () => {
            auth.isUser && subscriptionState.getActiveSubscriptionPlan();
        };
        fetchData();
    }, []);
    const [infoModalOpen, setInfoModalOpen] = useState(false);

    const isDisabled = (key) => !onlyForFree.includes(key) && !subscriptionState.hasSubscription
    const handleChange = (event) => {

        const { name, value, type, checked } = event.target;
        console.log(event);
        const applyValue = (obj, path, newValue) => {
            const keys = path.split('.');

            if (!onlyForFree.includes(keys[0]) && !subscriptionState.hasSubscription) {
                setInfoModalOpen(true)
                return;
            }

            const lastKey = keys.pop();
            const lastObj = keys.reduce((acc, key) => acc[key] = acc[key] || {}, obj);
            if (type === "checkbox") {
                lastObj[lastKey] = checked;
            } else {
                lastObj[lastKey] = newValue;
            }
        };

        applyValue(formState, name, value);


        // Update the state with the new formState object
        setFormState({ ...formState });

        if (name === "type") {
            if (formState.type === "all_pages") {
                setFormState({ ...formState, range_type: "range", range_start: 1, range_end: 5 });
            } else {
                setFormState({ ...formState, range_type: "full_page" });
            }
        }
    };


    const handleReset = () => {
        setFormState(initialFormState);
    };

    const handleSave = () => {
        toggleOptions(false);
    }
    return (
        <>
            <Modal
                isOpen={infoModalOpen}

                title="Preview PDF"
                Id="preview-pdf"
                setIsOpen={setInfoModalOpen}
                size="xl"
                buttonText="Close PDF">
                <ModalHeader setIsOpen={setInfoModalOpen}>
                    <ModalTitle Id="information"></ModalTitle>
                </ModalHeader>

                <ModalBody>
                    <div className="container">
                        <h5>Following options are available for only paid users:</h5>
                        <ul className="list-group">
                            <li className="list-group-item">Site Map</li>
                            <li className="list-group-item">Screen Size</li>

                            <li className="list-group-item">Quality</li>
                            <li className="list-group-item">Orientation</li>
                            <li className="list-group-item">Custom Watermark</li>
                            <li className="list-group-item">File Type</li>
                            <li className="list-group-item">Add Page Number</li>
                            <li className="list-group-item">Display Header & Footer</li>
                            <li className="list-group-item">Custom Header & Footer</li>
                            <li className="list-group-item">Notify me via email when the PDF is ready</li>
                        </ul>
                        <p>
                            <span className=' text-secondary responsive-text'>
                                <a href='/auth/sign-up' className='mx-2' >
                                    Signup now
                                </a> to get all exiciting features unlocked
                            </span>
                        </p>
                    </div>
                </ModalBody>
            </Modal>
            <div className="pdf-options">
                <div className="container conversion-options">
                <div className="new-section">
                    <div className="row options-area">
                        
                        <div className="col-md-7">
                            <div className="section-heading mb-3">
                                <h5>Conversion Settings</h5>
                            </div>
                            
                            <div className="form-group ">
                                <label htmlFor="pageSize"><strong>Page Size</strong></label>
                                <select disabled={isDisabled("format")} className="form-control" id="format" name="format" value={formState.format} onChange={handleChange}>
                                    {convertOptions.sizes.map((size, index) => (
                                        <option key={index} value={size.value}>{size.name}</option>
                                    ))}
                                </select>
                            </div>
                            {true && (
                                <>
                                    <div className="form-group map-area row">
                                        <label className="col-sm-3 col-form-label"><strong>Site Map</strong></label>
                                        <div className="col-sm-9">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <input disabled={isDisabled("type")} id="current_page" className="form-check-input style-radio" value="current_page" type="radio" name="type" checked={formState.type === "current_page"} onChange={handleChange} />
                                                    <label htmlFor="current_page" className="form-check-label">Current page</label>
                                                </div>
                                                <div className="col-md-4">
                                                    <input disabled={isDisabled("type")} id="all_page" className="form-check-input style-radio" value="all_pages" type="radio" name="type" checked={formState.type === "all_pages"} onChange={handleChange} />
                                                    <label htmlFor="all_page" className="form-check-label">All Pages</label>
                                                </div>
                                                <div className="col-md-4">
                                                    <input disabled={isDisabled("type")} id="bulk_pdf" className="form-check-input style-radio" value="bulk_pdf" type="radio" name="type" checked={formState.type === "bulk_pdf"} onChange={handleChange} />
                                                    <label htmlFor="bulk_pdf" className="form-check-label">Bulk</label>
                                                </div>
                                            </div>
                                            {formState.type === "all_pages" && (
                                                <button disabled={isDisabled("type")} type="button" onClick={handleGetPages} className="btn btn-secondary ms-auto scan-btn">Scan</button>
                                            )}

                                            {formState.type === "bulk_pdf" && (
                                                <button disabled={isDisabled("type")} type="button" onClick={openbulkDataModal} className="btn btn-secondary ms-auto scan-btn-add">Add More</button>
                                            )}


                                        </div>
                                    </div>
                                    {/* <div className="form-group">
                                    <label className="form-label">Page Range Selection</label>
                                    <div className="d-flex align-items-center my-2">
                                        <div className="form-check me-4">
                                            <input
                                                id="full_page"
                                                className="form-check-input"
                                                type="radio"
                                                name="range_type"
                                                value="full_page"
                                                checked={formState.range_type === "full_page"}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="full_page" className="form-check-label">Full Page</label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                id="range"
                                                className="form-check-input"
                                                type="radio"
                                                name="range_type"
                                                value="range"
                                                checked={formState.range_type === "range"}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="range" className="form-check-label">Select Range</label>
                                        </div>
                                    </div>
                                    {formState.range_type === "range" && (
                                        <div className="form-group mt-2">
                                            <div className="row g-2 align-items-center">
                                                <div className="col-auto">
                                                    <label htmlFor="range_start" className="col-form-label">From:</label>
                                                </div>
                                                <div className="col">
                                                    <input
                                                        id="range_start"
                                                        className="form-control"
                                                        type="number"
                                                        name="range_start"
                                                        placeholder="Start"
                                                        value={formState.range_start < 1 ? 1 : formState.range_start}
                                                        min="1" // Prevent negative values
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-auto text-center">
                                                    to
                                                </div>
                                                <div className="col">
                                                    <input
                                                        id="range_end"
                                                        className="form-control"
                                                        type="number"
                                                        name="range_end"
                                                        placeholder="End"
                                                        value={+formState.range_end > +formState.range_start ? formState.range_end : formState.range_start}
                                                        min={formState.range_start}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div> */}
                                </>
                            )}

                            {/* <div className="form-group">
                                <label htmlFor="screenSize">Screen Size</label>
                                <select className="form-control" id="screenSize" name="screenSize" value={formState.screenSize} onChange={handleChange}>
                                    {convertOptions.sizes.map((size, index) => (
                                        <option key={index} value={size.value}>{size.name}</option>
                                    ))}
                                </select>
                            </div> */}

                            {/* <div className="form-group">
                                <label htmlFor="Screen Size">Screen Size</label>
<ScreenResolutionDropdown  selectedValue={formState.screenSize} setSelectedValue={handleChange}/>
                            </div> */}



                            {/* <div className="form-group">
                            <label htmlFor="customSize">Custom Size</label>
                            <div className="row">
                                <div className="col-sm-6">
                                    <input className="form-control" type="number" name="width" placeholder="Width" value={formState.width} onChange={handleChange} />
                                </div>
                                <div className="col-sm-6">
                                    <input className="form-control" type="number" name="height" placeholder="Height" value={formState.height} onChange={handleChange} />
                                </div>
                            </div>
                        </div> */}

                            <div className="form-group quality-area row">
                                <label className="col-sm-3 col-form-label"><strong>Quality</strong></label>
                                <div className="col-sm-9">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <input
                                                id="quality_low"
                                                className="form-check-input style-radio"
                                                value="30"
                                                type="radio"
                                                name="quality"
                                                disabled={isDisabled("quality")}
                                                checked={formState.quality == 30}
                                                onClick={handleChange}
                                            />
                                            <label htmlFor="quality_low" className="form-check-label">Low</label>
                                        </div>
                                        <div className="col-md-4">
                                            <input
                                                id="quality_medium"
                                                className="form-check-input style-radio"
                                                value="75"
                                                type="radio"
                                                name="quality"
                                                disabled={isDisabled("quality")}
                                                checked={formState.quality == 75}
                                                onClick={handleChange}
                                            />
                                            <label htmlFor="quality_medium" className="form-check-label">Medium</label>
                                        </div>
                                        <div className="col-md-4">
                                            <input
                                                id="quality_high"
                                                className="form-check-input style-radio"
                                                value="100"
                                                type="radio"
                                                name="quality"
                                                disabled={isDisabled("quality")}
                                                checked={formState.quality == 100}
                                                onClick={handleChange}
                                            />
                                            <label htmlFor="quality_high" className="form-check-label">High</label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* <div className="form-group">
                                <label>Orientation</label>
                                <div className="d-flex align-items-center">
                                    <div className="d-flex flex-grow-1">
                                        <div className="form-check me-3">
                                            <input id="landscape" className="form-check-input" value="landscape" type="radio" name="orientation" checked={formState.orientation === "landscape"} onChange={handleChange} />
                                            <label htmlFor="landscape" className="form-check-label">Landscape</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input id="portrait" className="form-check-input" value="portrait" type="radio" name="orientation" checked={formState.orientation === "portrait"} onChange={handleChange} />
                                            <label htmlFor="portrait" className="form-check-label">Portrait</label>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label"><strong>Margins (pixels)</strong></label>
                                <div className="col-sm-12">
                                    <div className="row">
                                        {Object.entries(formState.margin).map(([key, value]) => (
                                            <div key={key} className="col-sm-3">
                                                <input disabled={isDisabled("margin")} className="form-control" type="number" name={"margin." + key} placeholder={key.charAt(0).toUpperCase() + key.slice(1)} value={value} onChange={handleChange} />
                                            </div>
                                        ))}
                                    </div>
                                    <div className="form-check check-box-wrap">
                                    <input disabled={isDisabled("colorLayer")} id="enableColorLayer" className="form-check-input" type="checkbox" name="enableColorLayer" checked={formState.enableColorLayer} onChange={handleChange} />
                                    <label for="enableColorLayer">Add color layer:</label>
                                    
                                    {formState.enableColorLayer && (
                                    <>
                                    <input type="color" id="colorlayer-" name="colorLayer" value={formState.colorLayer} title="Choose color" onChange={handleChange} />      
                                    </>
                                )}
                              
                                </div>


                                </div>
                            </div>
                            {/* <div className="form-group">
                            <label htmlFor="zoom">Zoom (%)</label>
                            <input className="form-control" type="number" name="scale" placeholder="Zoom" value={formState.scale} onChange={handleChange} />
                        </div> */}
                        </div>
                        
                        {true && (
                            <div className="col-md-5">
                                <div className="section-heading mb-3">
                                    <h5><strong>Page Settings</strong></h5>
                                </div>
                                                                <div className="form-group">
                                    <label htmlFor="file_type"><strong>File Type</strong></label>
                                    <select disabled={isDisabled("file_type")} className="form-control" id="file_type" name="file_type" value={formState.file_type} onChange={handleChange}>
                                        <option value="pdf">Pdf</option>
                                        <option value="jpeg">Jpeg</option>
                                        <option value="png">Png</option>
                                        <option value="webp">Webp</option>
                                    </select>
                                </div>
                                
                                <h4>Add Custom Watermark</h4>
                                <div className="form-check check-box-wrap">
                                    <input id="watermark" disabled={isDisabled("watermark")} className="form-check-input" type="checkbox" name="watermark" checked={formState.watermark} onChange={handleChange} />
                                    <label htmlFor="watermark" className="form-check-label">Add Custom Watermark</label>
                              
                                </div>

                                {formState.watermark && (
                                    <>
                                          <input type="color" id="watermarkColor-" name="watermarkOptions.style.color" value={formState.watermarkOptions.style["color"]} title="Choose color" onChange={handleChange}/>
                                     
                                        <div className="form-group">
                                            <label>Watermark text</label>
                                            <textarea
                                            style={{ color: formState.watermarkOptions.style["color"] }}
                                                className="form-control"
                                                type="text"
                                                name="watermarkOptions.text"
                                                placeholder="Enter Watermark..."
                                                value={formState.watermarkOptions.text}

                                                onChange={handleChange} />
                                        </div>
                                        {/*
                                <label><strong>Watermark details</strong></label>
                                <div className="watermark-wrap">

                                    <label>Rotation degree</label>
                                    <div className="form-group">
                                        <input className="form-control" type="number" name="watermarkOptions.rotation" placeholder="Watermark rotation" value={formState.watermarkOptions.rotation} onChange={handleChange} />
                                    </div>
                                     <label>Font weight</label>
                                    <div className="form-group">
                                        <input className="form-control" type="number" name="watermarkOptions.style.font-weight" placeholder="Watermark font weight" value={formState.watermarkOptions.style["font-weight"]} onChange={handleChange} />
                                    </div> 
                                    <label>Font size</label>
                                    <div className="form-group">
                                        <input className="form-control" type="number" name="watermarkOptions.style.font-size" placeholder="Watermark font size" value={formState.watermarkOptions.style["font-size"]} onChange={handleChange} />
                                    </div>
                                  
                                </div>
                                  */}
                                    </>

                                )}

                                {
                                    formState.file_type == 'pdf' && formState.type == 'all_pages' && (
                                                                       <div className="form-group">

                                    <div className="form-check check-box-wrap">
                                        <input disabled={isDisabled("pageNumber")} id="pageNumber" className="form-check-input style-checkbox" type="checkbox" name="pageNumber" checked={formState.pageNumber} onChange={handleChange} />
                                         <label htmlFor="pageNumber" className="form-check-label "> Add Page Number</label>
                                    </div>
                                </div>

                                    )
                                }

 


                                <div className="form-check check-box-wrap">
                                    <input id="displayHeaderFooter" disabled={isDisabled("displayHeaderFooter")} className="form-check-input style-checkbox" type="checkbox" name="displayHeaderFooter" checked={formState.displayHeaderFooter} onChange={handleChange} />
                                    <label htmlFor="displayHeaderFooter" className="form-check-label">Display Header & Footer</label>
                                    </div>
                                
                                {formState.displayHeaderFooter && (
                                    <>
                                                                        <input type="color" id="colorPicker" name="headerFooterColor" value={formState.headerFooterColor} title="Choose color" onChange={handleChange}/>

                                        <label>Header Text</label>
                                        <div className="form-group">
                                            <textarea style={{ color: formState.headerFooterColor }} disabled={isDisabled("headerTemplate")} className="form-control" type="text" name="headerTemplate" placeholder="Header Text" value={formState.headerTemplate} onChange={handleChange} />
                                        </div>
                                        <label>Footer Text</label>
                                        <div className="form-group">
                                            <textarea style={{ color: formState.headerFooterColor }} disabled={isDisabled("footerTemplate")} className="form-control" type="text" name="footerTemplate" placeholder="Footer Text" value={formState.footerTemplate} onChange={handleChange} />
                                        </div>
                                    </>
                                )}
                                <div className="form-check check-box-wrap">
                                    <input disabled={isDisabled("notify")} id="notify" className="form-check-input" type="checkbox" name="notify" checked={formState.notify} onChange={handleChange} />
                                    <label htmlFor="notify" className="form-check-label">Notify me via email when the PDF is ready</label>
                                </div>
                            </div>
                        )
                        }
                    </div>
                    </div>
                    {
                        !subscriptionState.hasSubscription && !auth.isAdmin && (
                            <div className="footer-modal">
                            <div className="row justify-content-center align-items-center" >
                                <div className="col-md-6">
                                    <span className=' text-secondary d-block responsive-text'>
                                        Subscribe to enjoy all available options.
                                        {
                                        auth.isLoggedIn() ? <a href='/subscriptions' className='mx-2' >
                                            Subscribe now!
                                        </a>
                                         : (
                                        <a href='auth/sign-up' className='mx-2' >
                                            Signup now!
                                        </a>
                                        )}

                                    </span>
                                </div>
                                <div className="col-md-6">
                            <div className="form-group text-right">
                                <button type="button" className="btn btn-primary mr-2" onClick={handleSave}>Close</button>
                                <button type="button" className="btn btn-secondary" onClick={handleReset}>Reset</button>
                            </div>
                        </div>
                        </div>
                            </div>
                        )
                    }
                   
                </div>
            </div>
           
        </>
    );
};

export default observer(ConversionOptions);
