import React, { useEffect, useState } from 'react';
import NotificationToast from './components/notification/NotificationToast';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import CookiePolicy from './components/sections/CookiePolicy';
import CosmicOrbitLoader from '../components/CosmicOrbitLoader';
import { dynamicLoader } from '../helpers/helpers';

const CookiePolicyPage = () => {
    const [showPreloader, setShowPreloader] = useState(true);
  dynamicLoader.addStyleSheets();
  document.title = "Web2PDF";
  useEffect(() => {
    dynamicLoader.addScripts();
    setTimeout(() => {
      setShowPreloader(false);
    }, 3000);
  });
  if (showPreloader) {
    return <CosmicOrbitLoader />;
  }
  return (
    <>
      <NotificationToast />
      <Navbar isIndex={false} />
      <CookiePolicy/>
      <Footer />
    </>

  );
};

export default CookiePolicyPage;
